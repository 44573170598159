import { BillingAmount } from "./Provider";

export class RegistrationView {
  firstName: string;
  lastName: string;
  title: string;
  subdomain: string;
  photo: string;
  gender: boolean = null;
  email: string;
  phoneNumber: string;
  whatsAppNumber: string;
  isNeedNumber: boolean = false;
  socialLogin: boolean = false;
  // billing
  //localPrice: number;
  ///internationalPrice: number;
  ///currencyCode: string;
  pricing: Array<BillingAmount> = [];
  calendlyLink: string;
  referralCode: string;
  personalLink: string;
  calendlyUsername: string;
  paymentType: paymentType;
}

export enum paymentType {
  fawry = 1,
  stripe = 2,
  payPal = 3,
}
export class RegistrationForm {
  email: string;
  confirmation_password: string;
  password: string;
  recaptchaResponse: string;
}
