
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplaceService } from '../service/marketplace.service';
import { UplaodImagesService } from '../service/uplaod-images.service';
import { CurrencyPipe } from '@angular/common';
import countryToCurrency from "country-to-currency";
import { RegistrationView } from "../models/RegistrationView";
import { BillingAmount } from "./../models/Provider";
import { HttpErrorResponse } from '@angular/common/http';
import { browserRefresh } from '../app.component';

@Component({
  selector: "app-payment-checkout",
  templateUrl: "./payment-checkout.component.html",
  styleUrls: ["./payment-checkout.component.css"]
})

///General Note any comments here or commented code is very useful to us in the future so please don't erase any of them.
export class PaymentCheckoutComponent implements OnInit, AfterViewInit {
  //money = formatCurrency(10,this.locale,'USD');
  @ViewChild("f") f: NgForm;
  @ViewChild('internationalPriceButton') internationalPriceReference: ElementRef;
  @ViewChild('localPriceButton') localPriceReference: ElementRef;
  browserRefresh: boolean;

  CurrencyPriceToday: number;
  ///
  Basic_Btn: string
  Profi_Btn: string
  USD_Vl;
  ///interPrice; => deprecated (i replaced by pricing in userModel) 
  ///locPrice;  => deprecated (i replaced by pricing in userModel)
  minLocalPrice: number = 1.5
  maxLocalPrice: number = 100
  minInternationalPrice: number = 1.5
  maxInternationalPrice: number = 100
  usd_symbol: string = " USD";
  International_field;
  LocalRate_field;
  localPriceError: boolean = false;
  internationalPriceError: boolean = false;
  ///
  localPriceIsEmpty: boolean = false;
  internationalPriceIsEmpty: boolean = false;

  // if the reqiered fields is untouched (the begining pf the form) 
  localPriceUntouched: boolean = true;
  internationalUntouched: boolean = true;

  ///
  urlHaveParams: boolean = false;
  ///
  userModel;
  paymentErr: string = "";
  productType: string = "";
  providerID: string = "";
  ///
  localPriceEvent: any;
  internationalPriceEvent: any;

  promoCodeErrMsg: string = "";
  promocode_submited: boolean = false;
  basicPackage_price: number = 0;
  profiPackage_price: number = 0;

  formSubmitted: boolean = false;

  consulting_price = new BillingAmount();

  constructor(private currencyPipe: CurrencyPipe, private uploadService: UplaodImagesService,
    private onboarding: MarketplaceService,
    private route: ActivatedRoute,
    private router: Router) {
    this.userModel = new RegistrationView();
    this.userModel.pricing.push(new BillingAmount());
    this.userModel.pricing.push(new BillingAmount());
    this.userModel.pricing[0].value = "1.5"
    this.userModel.pricing[1].value = "1.5"
    /*
    Note =>>>>>>
    pricing[0] => for local price 
    pricing[1] => for international price
    */
    var lastFormState = this.router.getCurrentNavigation().extras.state;

    if (lastFormState != null) {
      var countrycode = lastFormState.countryCode;
      this.userModel.phoneNumber = lastFormState.phoneNumber;
      this.userModel.whatsAppNumber = lastFormState.whatsNumber;
      console.log(this.userModel.phoneNumber, " , ", this.userModel.whatsAppNumber);
      this.userModel.pricing[0].currencyCode = countryToCurrency[countrycode];
      this.userModel.pricing[1].currencyCode = "USD";

      ///corner case 
      /*if (this.userModel.pricing[0].currencyCode == "SAR") {
        this.minLocalPrice = 5.5 ///set the default min val to 5.5 to be used in checking conditions
        this.userModel.pricing[0].value = "5.5"
      }
      */

      console.log(countrycode, " , ", this.userModel.pricing[0].currencyCode);
    }
    ///default prdouct is professional 
    this.productType = "SKU2"
    this.Basic_Btn = "b_btn"
    this.Profi_Btn = "p_btn"
  }

  handleRefersh() {
    this.browserRefresh = browserRefresh;
    console.log('refreshed?:', browserRefresh);
    if (this.browserRefresh) {
      window.location.replace("/");
    }
  }

  async ngOnInit() {
    this.handleRefersh()
    document.getElementById("errorMsg").style.display = "none";///close the err msg 
    ///this.CurrencyPriceToday=15.79; /// for testing if u didn't run back-end !!!!!!
    this.providerID = localStorage.getItem("guid");
    this.getUrlParams()
    this.getCurrencyToday();
    await this.getProductPrices(); ///fetch product prices from stripe dynamically
    this.fetchPromoCode();
    ///Note 
    ///can't call update currency here because have to ensure all angular elements initialzed first. must use ng AfterViewInit 
  }

  ngAfterViewInit() {
    ///now all angular elements had been initialized, so can update any element now.
    console.log("after view init")

    ///updating prices if URL has parameters (update with old values) or if not (update to default =1.5) 
    this.updateLocalAmount()
    this.updateUSAmount()
  }
  getCurrencyToday() {
    ///this.userModel.pricing[0].currencyCode="EGP"   //for local testing
    this.onboarding.getLocalPriceFromUSD(this.userModel.pricing[0].currencyCode)
      .subscribe(
        (res: any) => {
          this.CurrencyPriceToday = (res.rateFromUsd / 100);// convert from cents
          console.log("local price compared by USD = ", this.CurrencyPriceToday)
          return;
        },

        (error: HttpErrorResponse) => {
          var err = "there is an error in currency API = " + error.error.message;
          console.log(err);
          return err;
        },
      );

  }
  fetchPromoCode() {
    if (localStorage.getItem("guid_refferralCode")) {
      this.userModel.referralCode = localStorage.getItem("guid_refferralCode")
      this.CheckPromoCode("basic_amount", "profi_amount", "PriceTotal");
    }
  }
  URLPreProcess() {
    var hashURL = window.location.hash;   /// /#/payment-checkpot?
    var urlParamFirstIdx = hashURL.indexOf("?")
    if (urlParamFirstIdx == -1) {
      urlParamFirstIdx = hashURL.length
    }
    var urlParamsstring = hashURL.substring(urlParamFirstIdx)
    return urlParamsstring
  }

  getUrlParams() {
    //const urlParams = new URLSearchParams(window.location.search); =>> doesn't work with (# url) 
    ///so i need to make preprocessing to can fetch parameters 
    ///= >>>>
    var WindowLocationSearch = this.URLPreProcess() // this function will return as window.location.search 
    const urlParams = new URLSearchParams(WindowLocationSearch);

    console.log(window.location.search)
    console.log(window.location.hash)
    if (urlParams.toString().length > 0) ///because if will convert string to float can't convert empty sting to float will be NAN
    {
      this.urlHaveParams = true;
      this.userModel.pricing[0].value = this.priceEqFromCents(urlParams.get("localPrice"));
      this.userModel.pricing[1].value = this.priceEqFromCents(urlParams.get("internationalPrice"));

      this.userModel.pricing[0].currencyCode = urlParams.get("currencyCode");
      this.userModel.pricing[1].currencyCode = "USD";

      this.userModel.calendlyLink = urlParams.get("calendlyLink");
      this.userModel.referralCode = urlParams.get("referralCode");

      this.userModel.phoneNumber = urlParams.get("phoneNumber");
      this.userModel.whatsAppNumber = urlParams.get("whatsNumber");

      this.paymentErr = urlParams.get("paymentErr");
      this.providerID = urlParams.get("providerId");

      ////important and needed for eror msg from stripe
      /*
      document.getElementById("warningSign").style.display = 'inline';
      document.getElementById("errorMsg").style.display = 'inline';
      document.getElementById("errorMsg").innerHTML = this.paymentErr;
      */
    }
    else {
      document.getElementById("warningSign").style.display = 'none';
      this.urlHaveParams = false;
    }
    console.log(this.userModel.pricing[0].value, this.userModel.pricing[1].value, this.userModel.pricing[0].currencyCode, this.userModel.pricing[1].currencyCode, this.userModel.calendlyLink, this.userModel.referralCode, this.paymentErr, this.providerID);
  }

  priceEqToCents(price) {
    return Math.ceil(parseFloat(price) * 100) ///int value 
  }
  priceEqFromCents(price) {
    return (parseFloat(price) / 100.0).toString() ///string value
  }

  async getProductPrices(): Promise<void> {

    return new Promise((resolve, reject) => {
      this.onboarding.GetStripeProductPrices(this.providerID).subscribe(
        (res: any) => {
          console.log("productPriceList = ", res);
          console.log("product prices from stripe\n first price = ", res.prices[0], " , second = ", res.prices[1])

          this.basicPackage_price = Number(res.prices[1]) / 100; ///subdomain
          this.profiPackage_price = ( Number(res.prices[0]) + Number(res.prices[1]) ) / 100; ///subdomain + publicNumber
          resolve(); //return true
        },
        (error: HttpErrorResponse) => {
          console.log(error)
          resolve();
        }
      );

    })

  }

  create_stripe_session() {
    console.log(this.priceEqToCents(this.userModel.pricing[0].value), this.priceEqToCents(this.userModel.pricing[1].value))

    this.consulting_price.currencyCode = this.userModel.pricing[0].currencyCode;
    this.consulting_price.value = this.priceEqToCents(this.userModel.pricing[0].value)
    console.log("GO => ", this.providerID)
    ///for testing to change number because it's will be unique in DB !!!!!
    //this.userModel.phoneNumber="+2010000000205"; 
    //this.userModel.whatsAppNumber="+2010000000205";
    this.onboarding.InitiateSubscription(this.providerID, this.productType, this.consulting_price,
      this.priceEqToCents(this.userModel.pricing[1].value), this.userModel.referralCode, this.userModel.calendlyLink).subscribe((data: any) => {
        console.log("data received from initiateSubscription: ", data);
        const session_url = data.sessionUrl;
        //window.location.href = session_url;
        window.location.replace(session_url);
      },
        (error: HttpErrorResponse) => {
          console.log("Error", error);
          this.formSubmitted = false;
        },
        () => {
          console.log("POST is completed");
        })
  }
  onChange(lbl, btn_ID, productType) {
    console.log("product type => ", productType);
    this.productType = productType;

    console.log(lbl, ",", btn_ID)
    if (btn_ID == "b_btn") {
      document.getElementById("SKU1_Box").style.boxShadow = "5px 5px 10px rgba(248, 222, 222, 0.3)";
      document.getElementById("SKU1_Box").style.borderTop = "1px solid #5b316b";
      document.getElementById("SKU1_Box").style.opacity = "1.5";
      document.getElementById("SKU1_Box").style.transform = "translateY(-20px)";

      document.getElementById("SKU2_Box").style.boxShadow = "";
      document.getElementById("SKU2_Box").style.borderTop = "";
      document.getElementById("SKU2_Box").style.opacity = ".8";
      document.getElementById("SKU2_Box").style.transform = "";
      document.getElementById(lbl).innerHTML = "Total = $" + this.basicPackage_price.toFixed(2).toString() + " / month"
    }
    if (btn_ID == "p_btn") {
      document.getElementById("SKU2_Box").style.boxShadow = "5px 5px 10px rgba(248, 222, 222, 0.3)";
      document.getElementById("SKU2_Box").style.borderTop = "1px solid #5b316b";
      document.getElementById("SKU2_Box").style.opacity = "1.5";
      document.getElementById("SKU2_Box").style.transform = "translateY(-20px)";

      document.getElementById("SKU1_Box").style.boxShadow = "";
      document.getElementById("SKU1_Box").style.borderTop = "";
      document.getElementById("SKU1_Box").style.opacity = ".8";
      document.getElementById("SKU1_Box").style.transform = "";
      document.getElementById(lbl).innerHTML = "Total = $" + this.profiPackage_price.toFixed(2).toString() + " / month"
    }
  }
  updateUSAmount() {
    console.log("in US amount!!!")
    //if the user touch the international price
    this.internationalUntouched = false;

    if (this.userModel.pricing[1].value.length == 0) {
      this.internationalPriceIsEmpty = true;
      this.internationalPriceError = false;
    }
    this.International_field = this.currencyPipe.transform(this.userModel.pricing[1].value, '$');
    if (this.International_field == null) {
      this.internationalPriceReference.nativeElement.value = "";
    }
    else {
      this.internationalPriceIsEmpty = false;
      this.International_field = this.International_field + this.usd_symbol;
      this.internationalPriceReference.nativeElement.value = this.International_field;
    }
  }
  updateLocalAmount() {
    console.log("in local amount!!!")
    //if the user touch the local price
    this.localPriceUntouched = false;

    if (this.userModel.pricing[0].value.length == 0) {
      this.localPriceIsEmpty = true;
      this.localPriceError = false;
    }
    this.LocalRate_field = this.currencyPipe.transform(this.userModel.pricing[0].value, '', '');
    if (this.LocalRate_field == null) {
      this.localPriceReference.nativeElement.value = "";
    }
    else {
      this.localPriceIsEmpty = false;
      this.LocalRate_field = this.LocalRate_field + " " + this.userModel.pricing[0].currencyCode;
      this.localPriceReference.nativeElement.value = this.LocalRate_field;
    }
  }

  autoCalcInternationalRate() { ///set value of international price field then call update US amount function that make currency pipe
    this.userModel.pricing[1].value = this.convertToUSD(this.userModel.pricing[0].value)

    if (this.userModel.pricing[1].value > this.maxInternationalPrice) {
      this.userModel.pricing[1].value = this.maxInternationalPrice;
    }
    else if (this.userModel.pricing[1].value < this.minInternationalPrice) {
      this.userModel.pricing[1].value = this.minInternationalPrice;
    }
    this.updateUSAmount();
    this.checkPrice(this.userModel.pricing[1].value, 2);


  }

  ///takes local price to convert to USD amount
  convertToUSD(localPrice): string {
    if (localPrice.length == 0) return "";
    else {
      var eq = Number(localPrice) / Number(this.CurrencyPriceToday);
      return eq.toString();
    }
  }

  checkPrice(val, type) {
    let price = "", minPrice, maxPrice;
    if (type == 1) {
      minPrice = this.minLocalPrice
      maxPrice = this.maxLocalPrice
    }
    else {
      minPrice = this.minInternationalPrice
      maxPrice = this.maxInternationalPrice
    }
    this.userModel.pricing[type - 1].value = price = val;
    console.log("checkprice = ", this.userModel.pricing[type - 1].value)
    ///
    let check = (parseFloat(price) >= minPrice && parseFloat(price) <= maxPrice) || price.length == 0;
    console.log("price " + price + "  type " + type + "   check " + check);
    if (type == 1) {
      ///check if price is empty to update "local rate is required" error msg while writing in textbox"
      this.localPriceIsEmpty = (price.length == 0) ? true : false;

      this.localPriceError = !check;

      //I call auto_calc here because check_function calls during writing local price => it will facilitate conversion to USD on the fly
      if (!this.localPriceError && !this.localPriceIsEmpty) {

        this.autoCalcInternationalRate()
        ///then need to automatic check international rate
        //this.checkPrice(this.userModel.pricing[1].value,2);
      }
      /*else 
      {
        //this.userModel.pricing[1].value="";
        this.autoCalcInternationalRate(true) 
        //this.resetInternationalRateFields();
      }*/
    }
    if (type == 2) {
      ///check if price is empty to update "international rate is required" error msg while writing in textbox"
      this.internationalPriceIsEmpty = (price.length == 0) ? true : false;

      this.internationalPriceError = !check;
    }

  }

  resetInternationalRateFields() {
    this.userModel.pricing[1].value = "";
    this.internationalPriceReference.nativeElement.value = "";
    this.internationalPriceIsEmpty = false;
    this.internationalPriceError = false;
    this.internationalUntouched = true;
  }

  onSubmit(form) {
    console.log("on sumbit")
    if (!this.localPriceError && !this.localPriceIsEmpty && !this.localPriceUntouched
      && !this.internationalPriceError && !this.internationalPriceIsEmpty && !this.internationalUntouched) {
      this.formSubmitted = true;
      this.create_stripe_session();
    }
  }

  CheckPromoCode(lbl1, lbl2, lbl3) {
    console.log("in promoCode!!!", this.userModel.referralCode);
    this.promoCodeErrMsg = "";
    this.onboarding.verifyPromoCode(this.userModel.referralCode, this.providerID).subscribe((res: any) => {
      this.basicPackage_price = res.packageOnePrice / 100;
      this.profiPackage_price = res.packageTwoPrice / 100;
      document.getElementById(lbl1).innerHTML = "" + Number(this.basicPackage_price).toFixed(2).toString();
      document.getElementById(lbl2).innerHTML = "" + Number(this.profiPackage_price).toFixed(2).toString();
      document.getElementById(lbl3).innerHTML = "Total = $" + Number(this.profiPackage_price).toFixed(2).toString() + " / month";
      if (res.promoVerified) {
        this.promocode_submited = true;
        this.promoCodeErrMsg = "";
      }
      else {
        if (this.userModel.referralCode) {
          console.log("Promo Code is Not valid");
          this.promoCodeErrMsg = "Refferal Code is invalid";
        }
      }
    },
      (error: HttpErrorResponse) => {
        console.log(error.error.message)
      },
    );
  }
}
