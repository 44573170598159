import { BrowserModule } from "@angular/platform-browser";
import { Component, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { WizardComponent } from "./wizard/wizard.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { PaymentComponent } from "./payment/payment.component";
import { FinishComponent } from "./finish/finish.component";
import { HeaderComponent } from "./header/header.component";
import { FinishUnsbscribeComponent } from "./finish-unsbscribe/finish-unsbscribe.component";
import { UnsubscribeProviderComponent } from './unsubscribe-provider/unsubscribe-provider.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { PaymentCheckoutComponent } from './payment-checkout/payment-checkout.component';
import { CheckoutSccussComponent } from './checkout-sccuss/checkout-sccuss.component';
import { CurrencyPipe } from '@angular/common';
import { SocialLoginComponent } from './social-login/social-login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountdownModule } from 'ngx-countdown';
import { NgxCurrencyInputModule } from 'ngx-currency-input';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMaskModule, IConfig } from 'ngx-mask'

const maskConfig: Partial<IConfig> = {
  validation: false,
};
const routes: Routes = [
  {
    path: "",
    component: SocialLoginComponent,
  },
  {
    path: "form2",
    component: WizardComponent,
  },
  {
    path: "payment",
    component: PaymentComponent,
  },
  {
    path: "payment-checkout",
    component: PaymentCheckoutComponent,
  },
  {
    path: "succeeded",
    component: CheckoutSccussComponent,
  },
  {
    path: "finish",
    component: FinishComponent,
  },
  {
    path: "finishUnsubscribe",
    component: FinishUnsbscribeComponent,
  },
  {
    path: "unsubscribe",
    component: UnsubscribeProviderComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    WizardComponent,
    PaymentComponent,
    FinishComponent,
    HeaderComponent,
    FinishUnsbscribeComponent,
    UnsubscribeProviderComponent,
    PaymentCheckoutComponent,
    CheckoutSccussComponent,
    SocialLoginComponent,
  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' }),
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxCurrencyInputModule,
    Ng2TelInputModule,
    CountdownModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [CurrencyPipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
