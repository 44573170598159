import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common"
import { MarketplaceService } from "./../service/marketplace.service";
import { environment } from "./../../environments/environment";
@Component({
  selector: 'app-unsubscribe-provider',
  templateUrl: './unsubscribe-provider.component.html',
  styleUrls: ['./unsubscribe-provider.component.css']
})
export class UnsubscribeProviderComponent implements OnInit {
  public submitted = false;
  public loggedIn = false;
  public recaptchaPublicKey = environment.recaptcha.siteKey;
  queryProvider_subdomain = '';
  queryTokenNo = '';
  queryCaptchaResponse = '';
  constructor(private route: ActivatedRoute, private router: Router, private onboarding: MarketplaceService) {
    this.route.queryParams.subscribe(data => {
      this.queryProvider_subdomain = data.provider_subdomain;
      this.queryTokenNo = data.token_no;
    })
  }
  ngOnInit() { }
  @ViewChild('unsubscribeForm') public unsubscribeForm: NgForm;

  public resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.passingRecaptcha(captchaResponse);
    //this.document.location.href = 'https://backend.instaconsult.me/unsubscribe?' + this.queryProvider_subdomain +'&' + this.queryTokenNo + '&' + captchaResponse;
    //this.document.location.href = 'https://onboarding.instaconsult.me/finishUnsubscribe'
  }

  public Unsubscribe = {
    provider_ID: "",
    tokenNo: "",
    recaptcha: "",
  }
  public passingRecaptcha(captchaResponse: string) {
    this.queryCaptchaResponse = captchaResponse;
  }
  onSubmit(form) {
    console.log(form.value);

    console.log(this.queryCaptchaResponse);
    this.onboarding.unsubscribeProvider(this.queryProvider_subdomain, this.queryTokenNo, this.queryCaptchaResponse).subscribe((res: any) => {
      if (Object.keys(res.length == 0).length == 0) {
        this.submitted = true;
        this.router.navigate(["/finishUnsubscribe"]);
      }
    });

  }
}
