import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PatternValidator } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checkout-sccuss',
  templateUrl: './checkout-sccuss.component.html',
  styleUrls: ['./checkout-sccuss.component.css'],
})
export class CheckoutSccussComponent implements OnInit {
  twillioNumber: string = "";
  subdomain: string = "";
  b: boolean = true;
  contentURL: string;
  facebook_URL: string = "";
  twiiter_URL: string = "";
  whatsApp_URL: string = "";
  linkedIn_URL: string = "";
  USNumber: any = "";

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute) {
  }


  ngOnInit() {
    this.getUrlParams();
    this.socialButtons();
  }
  socialButtons() {
    if (this.twillioNumber === "") {
      this.contentURL = document.getElementById("cardText").textContent.toString()
        + "https://" + this.subdomain + ".instaconsult.me";
    }
    else {
      this.USNumber = this.twillioNumber;
      this.USNumber = this.USNumber.substring(2);
      this.USNumber = this.USNumber.match(/(\d{3})(\d{3})(\d{4})/);
      this.USNumber = "+1 (" + this.USNumber[1] + ") " + this.USNumber[2] + "-" + this.USNumber[3];
      console.log(this.USNumber);
      //console.log(document.getElementById("providerPhoneNumber").textContent);
      this.contentURL = document.getElementById("cardText").textContent.toString()
        + "https://" + this.subdomain + ".instaconsult.me"
        + " or " + this.USNumber;
    }
    console.log(this.contentURL);
    this.facebook_URL = "https://www.facebook.com/sharer/sharer.php?u=https://" + this.subdomain + ".instaconsult.me&quote=" + encodeURIComponent(this.contentURL);
    this.twiiter_URL = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(this.contentURL);
    this.whatsApp_URL = "whatsapp://send?text=" + encodeURIComponent(this.contentURL);
    this.linkedIn_URL = "http://www.linkedin.com/shareArticle?mini=true&url=https://" + this.subdomain + ".instaconsult.me&title=" + encodeURIComponent(this.contentURL) + "source=instaconsult.me";
  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
  URLPreProcess() {
    var hashURL = window.location.hash;   /// /#/payment-checkpot?
    var urlParamFirstIdx = hashURL.indexOf("?")
    if (urlParamFirstIdx == -1) {
      urlParamFirstIdx = hashURL.length
    }
    var urlParamsstring = hashURL.substring(urlParamFirstIdx)
    return urlParamsstring
  }

  getUrlParams() {
    //const urlParams = new URLSearchParams(window.location.search); =>> doesn't work with (# url) 
    ///so i need to make preprocessing to can fetch parameters 
    ///= >>>>
    var WindowLocationSearch = this.URLPreProcess() // this function will return as window.location.search 
    const urlParams = new URLSearchParams(WindowLocationSearch);

    console.log(window.location.search)
    console.log(window.location.hash)

    if (urlParams.toString().length > 0) {
      this.twillioNumber = urlParams.get("publicNumber");
      if (this.twillioNumber.length > 0) {
        this.twillioNumber = this.twillioNumber.substring(1);///delete first space from the number 
        this.twillioNumber = "+" + this.twillioNumber; ///url params can't catch '+' char ,so i tried to add it manually
      }
      this.subdomain = urlParams.get("subdomain");
      console.log(this.twillioNumber + " , " + this.subdomain)
    }
  }
  // we can use this function to catch the query params faster. 
  catchQueryParams() {
    this.route.queryParams.subscribe(data => {
      if (data.publicNumber !== "") {
        this.twillioNumber = data.publicNumber.substring(1); ///delete first space from the number 
        this.twillioNumber = "+" + this.twillioNumber; ///url params can't catch '+' char ,so i tried to add it manually
      }
      this.subdomain = data.subdomain;
    })
    console.log(this.twillioNumber + " , " + this.subdomain)
  }
  hasPublicNumber() {
    console.log("hi => " + (this.twillioNumber.length > 0))
    return (this.twillioNumber.length > 0);
  }
  copyText() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    console.log(this.contentURL)
    selBox.value = this.contentURL;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
