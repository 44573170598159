import { ContactInfo, ContactMethod, Name, Profile } from "./../models/profile";
import { Kind, Provider } from "./../models/Provider";
import { MarketplaceService } from "./../service/marketplace.service";
import { UplaodImagesService } from "./../service/uplaod-images.service";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { RegistrationView } from "../models/RegistrationView";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "./../../environments/environment.prod";
import { phone } from "phone";
import { VisitorsService } from './../service/visitors.service';
import { HttpErrorResponse } from "@angular/common/http";
import { CountdownComponent } from 'ngx-countdown';
import { fromArabic } from 'arabic-digits';
import { toArabic } from 'arabic-digits';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: "app-wizard",
  templateUrl: "./wizard.component.html",
  styleUrls: ["./wizard.component.css"],
})
export class WizardComponent implements OnInit {
  wizard = 1;
  succesfulRegMsg: string;
  emailCode: string;
  whatsAppNumberCode: string;
  phoneNumberCode: string;

  isSubdomainValid: boolean = true;

  emailCodeSend: boolean = false;
  phoneCodeSend: boolean = false;
  whatsAppCodeSend: boolean = false;

  errorEmail: boolean = false;
  errorPhoneCode: boolean = false;
  errorWhatsAppCode: boolean = false;

  verifyEmailCode: boolean = false;
  verifyPhoneNumber: boolean = false;
  verifyWhatsAppNumber: boolean = false;

  verifyWhatsAppButton: boolean = false;
  verifyPhoneNumberButton: boolean = false;

  checkWhatsAppCodeSend: boolean = false;
  checkPhoneCodeSend: boolean = false;
  whatsNumberIsUsed: boolean = false;

  phoneNumberErrMsg: string = "";
  whatsNumberErrMsg: string = "";

  isPhoneNumberInCountDown: boolean = false;
  isWhatsNumberInCountDown: boolean = false;

  photoName: string;
  photoSrc: string;
  filetoUpload: File = null;

  countries: any;

  phoneNumberClicked = false;
  whatsAppNumberClicked = false;

  whatsAppChecked = true;
  whatsAppCheckboxIsExist = true;

  phoneNumberFieldIsDisabled = false;
  whatsAppNumberFieldIsDisabled = false;

  phoneNumberVerificationAttempts = 3;
  phoneNumberVerificationAttemptsMsgIsDisplayed = true; ///msg of number of attempts

  ipaddress: string = '';
  ip_country_code: string = '';
  ip_country_name: string = '';

  userModel = new RegistrationView();

  provider = new Provider();
  profile = new Profile();

  telOptions = { preferredCountries: ['eg', 'sa'] };
  PhoneNumberField: string = '';
  WhatsNumberField: string = '';
  noError_PhoneNumber: boolean;
  noError_WhatsAppNumber: boolean;
  countryCode_PhoneNumber: string = "EG";
  subdomains: any;

  gender_label: boolean = true;

  infoFormIsLoading: boolean = false;

  phoneNumberSendLoading: boolean = false;
  whatsAppNumberSendLoading: boolean = false;

  phoneCodeLoading: boolean = false;
  whatsAppCodeLoading: boolean = false;

  NumbersFormLoading: boolean = false;
  @ViewChild("f") f: NgForm;
  @ViewChild('countdown') counter: CountdownComponent;
  @ViewChild('resendOTP') resendPhone: ElementRef;
  @ViewChild('resendOTP_2') resendWhatsApp: ElementRef;
  @ViewChild('phoneNumberBtn') PhoneNumber_Button;
  @ViewChild('whatsAppNumberBtn') WhatsAppNumber_Button;

  @ViewChild('phoneCodeBtn') PhoneCode_Button;
  @ViewChild('whatsAppCodeBtn') WhatsAppCode_Button;
  constructor(private uploadService: UplaodImagesService,
    private onboarding: MarketplaceService,
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    private visitorsService: VisitorsService, private cdref: ChangeDetectorRef) {
    this.userModel.title = "";
    this.userModel.gender = true;
    this.profile.contact_info = new ContactInfo();
    this.profile.contact_info.emails.push(new ContactMethod());
    this.profile.contact_info.emails[0].value = "";
  }
  
  async ngOnInit() {
    await this.getUserModelInfo(); ///get user info first
    this.getSubdomains(); ///then get all subdomains
  }
  ngAfterContentChecked() {
    /*
    let me descripe the reason of override this ng function 
    => i was getting errors when changing phone_number_textboxes to the read-only state. 
    because angular didn't detect the changes in values  ( isPhoneNumberInCountDown,isWhatsNumberInCountDown ) 
    {
      their values at first = false and will be changed when the (send) button is clicked to =true, so the read-only state will be changed in the textboxes.
      but the ng cycle didn't catch the change quickly, so I force it to see the change. by => this.cdref.detectChanges();
    }
    
    for better clearification =>
      https://stackoverflow.com/questions/45467881/expressionchangedafterithasbeencheckederror-expression-has-changed-after-it-was 
      https://hackernoon.com/everything-you-need-to-know-about-change-detection-in-angular-8006c51d206f
    */
    this.cdref.detectChanges();

  }
  telInputObject(obj) {
    this.visitorsService.getIpAddress().subscribe(res => {
      this.ipaddress = res['ip'];
      this.visitorsService.getGEOLocation(this.ipaddress).subscribe((res: any) => {
        this.ip_country_name = res.countryName;
        this.ip_country_code = res.countryCode;
        this.countryCode_PhoneNumber = this.ip_country_code;
        console.log(this.ip_country_name, this.ip_country_code);
        obj.setCountry(this.ip_country_code.toLowerCase());
      });
    });
  }

  subdomainFocus() {
    this.isSubdomainValid = true;
  }
  getNumber(obj, id) {
    console.log(obj, id);
    if (id == 1) {
      this.PhoneNumberField = obj;
    }
    if (id == 2) {
      this.WhatsNumberField = obj;
    }
  }
  finishTest(objAction) {
    console.log(objAction, "heeerere phone counter");
    this.isPhoneNumberInCountDown = true;
    if (objAction == 'done') {
      console.log("timer1 is done");
      this.isPhoneNumberInCountDown = false;
      this.phoneNumberClicked = false;
      if (this.resendPhone != null) {
        this.resendPhone.nativeElement.disabled = true;
      }
    }
  }
  finishTestCounter(objAction) {
    console.log(objAction, "heeerere what'sApp counter");
    this.isWhatsNumberInCountDown = true;
    if (objAction == 'done') {
      console.log("timer2 is done");
      this.isWhatsNumberInCountDown = false;
      this.whatsAppNumberClicked = false;
      if (this.resendWhatsApp != null) {
        this.resendWhatsApp.nativeElement.disabled = true;
      }
    }
    //(<HTMLInputElement>document.getElementById("phoneNumberBtn")).disabled = false;
  }
  countryChange_PhoneNumber(country: any) {
    this.countryCode_PhoneNumber = country.iso2.toUpperCase();
  }
  onError_PhoneNumber(obj) {
    this.noError_PhoneNumber = obj;
    console.log('No Errors: ', obj);
  }
  onError_WhatssAppNumber(obj) {
    this.noError_WhatsAppNumber = obj;
    console.log('No Errors: ', obj);
  }
  checkTilteFocus(event) {
    event.target.placeholder = ""
  }
  checkTilteBlur(event) {
    if (event.target.value.length == 0) {
      event.target.placeholder = "       [Optional]"
    }
    else {
      event.target.placeholder = "";
    }
  }


  getSubdomains() {
    let userId = localStorage.getItem("guid");
    console.log("user id = ", userId);

    this.onboarding.getAllSubdomains(userId).subscribe((data: any) => {
      this.subdomains = data.subdomain;
      console.log(this.subdomains);
    });
    console.log("finish subdomains");
  }

  async getUserModelInfo(): Promise<void> {

    this.route.queryParams.subscribe(data => {
      localStorage.setItem("guid", data.user_id);
      this.userModel.email = data.email;
      this.userModel.firstName = data.first_name;
      this.userModel.lastName = data.last_name;
      this.userModel.subdomain = (data.first_name + data.last_name).toLowerCase();
      this.photoSrc = decodeURIComponent(data.profile_picture);
      ///get SuccesfulRegMsg 
      this.succesfulRegMsg = data.verifyMsg
      if ( this.succesfulRegMsg.length > 0 )
      {
        document.getElementById("successfulMsg").innerHTML = "✔ "+this.succesfulRegMsg;
      }

    })
  
  }
  
  changeGender(event) {
    this.gender_label = false;
    this.userModel.gender = event == "Male" ? true : false;
  }

  // upload image
  handelFileInput(file: FileList) {
    this.filetoUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.photoSrc = event.target.result;
    };
    this.photoName = this.filetoUpload.name;
    reader.readAsDataURL(this.filetoUpload);
  }

  uploadImage() {
    if (this.filetoUpload) {
      this.uploadService.uploadImage(this.filetoUpload).subscribe((res) => { });
      this.userModel.photo = environment.apiUrl + "/uploads/" + this.photoName;
    }
    else {
      this.userModel.photo = this.photoSrc;
    }
  }

  registerProvider() {
    if (!this.gender_label) { // if the gender selected

      this.infoFormIsLoading = true;
      // user profile
      this.profile.id = localStorage.getItem("guid");
      this.profile.name = new Name();
      this.profile.name.first_name = this.userModel.firstName;
      this.profile.name.last_name = this.userModel.lastName;
      this.profile.title = this.userModel.title;
      this.profile.contact_info.emails[0].value = this.userModel.email;

      this.profile.gender = this.userModel.gender;
      this.profile.profile_picture = this.userModel.photo;
      console.log("email = " + this.profile.email + " gender = " + this.profile.gender + " title = " + this.userModel.title);
      // provider
      this.provider.userId = localStorage.getItem("guid");
      this.provider.subDomain = this.userModel.subdomain;

      this.onboarding
        .registerProvider(this.provider, this.profile)
        .subscribe((res: any) => {
          this.infoFormIsLoading = false;
          if (Object.keys(res.length == 0).length == 0) {
            this.wizard = 3;
            document.getElementById("successfulMsg").innerHTML="";
          }
        });
    }
  }

  createProfile() {
    if (this.checkSubdomain()) {
      console.log("subdomain is valid");
      this.isSubdomainValid = true;
      this.uploadImage();
      this.registerProvider();
    } else {
      this.isSubdomainValid = false;
    }
  }

  checkSubdomain() {
    let valid = true;
    this.subdomains.forEach((subdomain) => {
      if (subdomain == this.userModel.subdomain) {
        valid = false;
      }
    });
    return valid;
  }

  updateCalendlyName() {
    const indx = this.userModel.calendlyUsername.lastIndexOf("/");
    if (indx != -1) {
      this.userModel.calendlyUsername = this.userModel.calendlyUsername.substr(
        indx + 1
      );
    }
    this.userModel.calendlyUsername =
      "https://calendly.com/" + this.userModel.calendlyUsername;
    console.log(this.userModel.calendlyUsername);
  }

  checkPhoneWithCountryCode(countryCode, number) {
    console.log(countryCode + number);
    console.log(phone(countryCode, number).isValid);

    return phone(countryCode, number).isValid;
  }

  changePhoneNumber() {
    this.phoneNumberErrMsg = ""
  }
  resetError(type) {
    console.log(type)
    if(type ==  1){
      this.noError_PhoneNumber = true;
    } else {
      this.noError_WhatsAppNumber = true;
    }
  }
  changewhatsNumber() {
    this.whatsNumberErrMsg = ""
    this.whatsNumberIsUsed = false;///if what's Ap number is used before we have to remove error if user try to edit the number
  }
  sendPhoneNumberCode() {
    this.phoneNumberSendLoading = true;
    console.log(this.PhoneNumberField);
    let providerId = localStorage.getItem("guid");
    this.onboarding
      .sendSMSCode(
        providerId,
        this.PhoneNumberField
      )
      .subscribe(

        (res: any) => {
          this.phoneNumberSendLoading = false;
          if (res.verified) {
            this.phoneCodeSend = true;
            this.phoneNumberErrMsg = "";
            this.phoneNumberClicked = true;
            console.log(res.verified);
          }

        },
        (error: HttpErrorResponse) => {
          this.phoneNumberSendLoading = false;
          console.log("twilio client error = ", error.error.message)
          this.phoneCodeSend = false;
          this.phoneNumberErrMsg = "Internal Error occurred, please try again.";

        },
      );
  }

  sendWhatsAppNumberCode() {
    this.whatsAppNumberSendLoading = true;
    console.log(this.WhatsNumberField);
    let providerId = localStorage.getItem("guid");
    this.onboarding
      .sendWhatsAppCode(
        providerId,
        this.WhatsNumberField,
        false
      )
      .subscribe(
        (res: any) => {
          this.whatsAppNumberSendLoading = false;
          if (res.verified) {
            console.log("I am Here Right Now ^^ ");
            this.whatsAppCodeSend = true;
            this.whatsNumberIsUsed = false;
            this.whatsNumberErrMsg = "";
            this.whatsAppNumberClicked = true;
            console.log(res.verified);
          }
        },

        (error: HttpErrorResponse) => {
          this.whatsAppNumberSendLoading = false;
          this.whatsAppCodeSend = false;
          this.whatsNumberIsUsed = true;
          this.whatsNumberErrMsg = error.error.message;
        },
      );
  }

  changePhoneDigitsToEN(str) {
    if (str != null) {
      console.log(str);
      this.phoneNumberCode = fromArabic(str);
      console.log(this.phoneNumberCode);
    }
  }
  changeWhatsDigitsToEN(str) {
    if (str != null) {
      console.log(str);
      this.whatsAppNumberCode = fromArabic(str);
      console.log(this.whatsAppNumberCode);
    }
  }

  phoneVerified() {
    console.log("check phone code in verify sms val is verified")
    this.checkPhoneCodeSend = true;
    this.verifyPhoneNumber = true;
    ////close timer
    this.finishTest("done")
    ///set text_field in  countDown state "when it in count down (the textbox become readonly)"
    this.isPhoneNumberInCountDown = true;
    ///set send button "disabled"
    this.phoneNumberFieldIsDisabled = true;
  }

  whatAppAlreadyUsedOperations(errMsg: string) {
    this.NumbersFormLoading = false;
    this.whatsAppCodeSend = false;
    this.whatsNumberIsUsed = true;
    this.whatsNumberErrMsg = errMsg;
    this.whatsAppCheckboxIsExist = false; ///to hide the checkbox feature cuz we can't use what's number as phone number because it's already used before , so we have to change it.
    ///we need to uncheck the checkbox(Use the same number for WhatsApp communication) => because we can't use it because this number is already taken
    this.whatsAppChecked = false;
  }

  reloadPage() {
    if (this.phoneNumberVerificationAttempts <= 0) {
      console.log("maximum verifying attempts reached.  ...Refreshing")
      window.location.reload();
    }
  }

  checkPhoneNumberCode(phoneCode: string) {
    this.phoneCodeLoading = true;
    let providerId = localStorage.getItem("guid");
    this.verifyPhoneNumberButton = true;

    this.phoneNumberVerificationAttempts--;
    console.log("Remaining: " + this.phoneNumberVerificationAttempts + "verify attempts")

    if (this.phoneNumberVerificationAttempts >= 0) {
      this.onboarding
        .verifySMSCode(providerId, phoneCode, this.whatsAppChecked)
        .subscribe(
          (res: any) => {
            this.phoneCodeLoading = false;
            console.log("check phone code in verify sms ")
            if (res.verified) {
              console.log("phone is verified");
              this.phoneVerified();
              this.phoneNumberVerificationAttemptsMsgIsDisplayed = false; ///hide the message if the number is verified successfully

              ///(just whatsApp number is already used error) till now.
              ///if we will have many internal errors then we also need to check on error messages.  
              if (res.error != null) {
                console.log(" but whatsApp already used" + res.error + "," + res.error.description);
                this.whatAppAlreadyUsedOperations(res.error.description);
              }
            }
            else {
              console.log("check phone code in verify sms val is not verified")
              this.checkPhoneCodeSend = false;
              this.verifyPhoneNumber = false;
              ///check if user lose all attemps and got error in last trial
              this.reloadPage()
            }
          },
          (error: HttpErrorResponse) => {
            this.phoneCodeLoading = false;
            console.log("error from phone client server" + error.error.message)
            this.checkPhoneCodeSend = false;
            this.verifyPhoneNumber = false;
            ///check if user lose all attemps and got error in last trial
            this.reloadPage()
          }

        );
    } else {
      ///check if user lose all attemps
      this.reloadPage()
    }
  }

  /*
  process(event) {
    event.preventDefault();
 
    const phoneNumber = this.phoneInput.getNumber();
 
    this.info.style.display = "";
    this.info.innerHTML = `Phone number in E.164 format: <strong>${phoneNumber}</strong>`;
  }*/
  whatsAppVerified() {
    console.log("check whats code in verify whats val is verified")
    this.checkWhatsAppCodeSend = true;
    this.verifyWhatsAppNumber = true;
    ////close timer
    this.finishTestCounter("done")
    ///set text_field in  countDown state "when it in count down (the textbox become readonly)"
    this.isWhatsNumberInCountDown = true;
    ///set send button "disabled"
    this.whatsAppNumberFieldIsDisabled = true;
  }
  checkWhatsAppNumberCode(whatsAppCode: string) {
    this.whatsAppCodeLoading = true;
    let providerId = localStorage.getItem("guid");
    this.verifyWhatsAppButton = true;

    this.onboarding
      .verifyWhatsAppCode(providerId, whatsAppCode)
      .subscribe(
        (res: any) => {
          this.whatsAppCodeLoading = false;
          console.log("check whats code in verify whats ")
          if (res.verified) {
            this.whatsAppVerified();
          }
          else {
            console.log("check whats code in verify whats val is not verified")
            this.checkWhatsAppCodeSend = false;
            this.verifyWhatsAppNumber = false;
          }
        },
        (error: HttpErrorResponse) => {
          this.whatsAppCodeLoading = false;
          console.log("error from whatsApp client server")
          this.checkWhatsAppCodeSend = false;
          this.verifyWhatsAppNumber = false;
        }

      );
  }
  async tryToUpdateWhatsNumber(whatsNumber): Promise<boolean> {
    let providerId = localStorage.getItem("guid");
    console.log("try to update whatsNumber")

    return new Promise((resolve, reject) => {
      this.onboarding
        .sendWhatsAppCode(
          providerId,
          whatsNumber,
          true,
        )
        .subscribe(
          (res: any) => {
            if (res.verified) {
              ///we don't need to do anything if the number is saved automatically.
              console.log("WhatsApp number is saved successfully");
              resolve(true); //return true
            }
          },

          (error: HttpErrorResponse) => {
            this.whatAppAlreadyUsedOperations(error.error.message);
            //reject(false)
            resolve(false);///return false
          },
        );
    })

  }
  // first form
  infoForm(f) {
    this.createProfile();
  }

  // second form
  async numberForm() {
    let check = true;
    this.NumbersFormLoading = true;
    this.userModel.phoneNumber = this.PhoneNumberField;
    if (this.whatsAppChecked) {
      this.userModel.whatsAppNumber = this.userModel.phoneNumber;
      check = await this.tryToUpdateWhatsNumber(this.userModel.whatsAppNumber)
      console.log("the res from API = ", check)
    }
    else {
      this.userModel.whatsAppNumber = this.WhatsNumberField;
    }
    console.log(this.countryCode_PhoneNumber);
    console.log(check);

    if (check == true) {
      if (this.countryCode_PhoneNumber == undefined) {
        this.countryCode_PhoneNumber = "EG";
      }
      this.NumbersFormLoading = false;
      this.router.navigate(["/payment-checkout"], {
        state: {
          countryCode: this.countryCode_PhoneNumber, phoneNumber: this.userModel.phoneNumber, whatsNumber: this.userModel.whatsAppNumber
        }
      });
    }
  }
  EnterSubmitPhoneForm(event) {
    //keycode for Enter is 13 
    if (event.keyCode === 13) {
      //alert('Enter key is pressed, form will be submitted');
      //calling submit method if key pressed is Enter.
      this.PhoneNumber_Button.nativeElement.focus()
    }
  }
  EnterSubmitWhatsAppForm(event) {
    //keycode for Enter is 13 
    if (event.keyCode === 13) {
      //alert('Enter key is pressed, form will be submitted');
      //calling submit method if key pressed is Enter.
      this.WhatsAppNumber_Button.nativeElement.focus()
    }
  }
  EnterPhoneCodeForm(event) {
    //keycode for Enter is 13 
    if (event.keyCode === 13) {
      //alert('Enter key is pressed, form will be submitted');
      //calling submit method if key pressed is Enter.
      this.PhoneCode_Button.nativeElement.focus()
    }
  }
  EnterWhatsappCodeForm(event) {
    //keycode for Enter is 13 
    if (event.keyCode === 13) {
      //alert('Enter key is pressed, form will be submitted');
      //calling submit method if key pressed is Enter.
      this.WhatsAppCode_Button.nativeElement.focus()
    }
  }
  back(currentWizard: number) {
    this.wizard = currentWizard - 1;
    if (this.wizard == 1) {
      this.photoSrc = this.userModel.photo = this.filetoUpload = null;
    }
  }
}
