export class Profile {
  id: string;
  name: Name;
  title: string;
  email: string;
  gender: boolean;
  profile_picture: string;
  contact_info: ContactInfo;
}

export class ContactInfo {
  id: string;
  phone_numbers: Array<ContactMethod> = [];
  emails: Array<ContactMethod> = [];
}

enum Kind {
  UNDEFINED,
  PERSONAL,
  WORK,
}

export class ContactMethod {
  value: string;

  // The kind of that contact method
  kind: Kind;
}
export class Name {
  first_name: string;
  last_name: string;
  nick_name: string;
}
