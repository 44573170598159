import { Component, OnDestroy } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { Guid } from "guid-typescript";
import { Subscription } from "rxjs";

export let browserRefresh = false;///to handle when user trying to refresh the browser
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnDestroy {
  title = "onboardring-console";
  id: Guid = Guid.create();
  subscription: Subscription;

  constructor(private router: Router) {
    // set userId in storage
    localStorage.setItem("guid", this.id.toString());

    this.subscription = router.events.subscribe( (event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        console.log("App Component . Refreshing......");
      }
  });
  
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    console.log("ngOnDestroy")
  }
}
