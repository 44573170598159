import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finish-unsbscribe',
  templateUrl: './finish-unsbscribe.component.html',
  styleUrls: ['./finish-unsbscribe.component.css']
})
export class FinishUnsbscribeComponent implements OnInit {
  emailstring = "mailto:support@instaconsult.me?Subject= need a help!";
  constructor() { }

  ngOnInit() {
  }

}
