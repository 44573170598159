import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
})
export class PaymentComponent implements OnInit {
  fawry: string = "";
  stripe: string = "";
  paypal: string = "";
  constructor() {
    this.getButtons();
  }

  ngOnInit() { }
  getButtons() {
    this.fawry = JSON.parse(localStorage.getItem("fawry"));
    this.stripe = JSON.parse(localStorage.getItem("stripe"));
    this.paypal = JSON.parse(localStorage.getItem("paypal"));
    this.removeButtons();
  }
  removeButtons() {
    localStorage.removeItem("fawry");
    localStorage.removeItem("stripe");
    localStorage.removeItem("paypal");
  }
}
