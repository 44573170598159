import { Profile } from "./../models/profile";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../environments/environment.prod";
import { BillingAmount, Provider } from "../models/Provider";

const baseUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class MarketplaceService {
  constructor(
    private http: HttpClient,
  ) { }

  //Onboarding Get All Subdomain
  getAllSubdomains(userId: string) {
    var url = baseUrl + "/v1/marketplace/onboard/subdomains";
    var queryParams = "?userId=" + userId;
    return this.http.get(url + queryParams);
  }

  // Onboarding Send SMS Code
  sendSMSCode(providerId: string, number: string) {
    const body = { providerId, number };
    var url = baseUrl + "/v1/marketplace/onboard/sms";
    return this.http.post(url, body);
  }

  // Onboarding Send WhatsApp Code
  sendWhatsAppCode(providerId: string, number: string, saveWhatsAppNumber: boolean) {
    const body = { providerId, number, saveWhatsAppNumber };
    var url = baseUrl + "/v1/marketplace/onboard/whatsapp";
    return this.http.post(url, body);
  }

  // Onboarding Verify SMS Code
  verifySMSCode(providerId: string, code: string, saveWhatsAppNumber: boolean) {
    const body = { providerId, code, saveWhatsAppNumber };
    var url = baseUrl + "/v1/marketplace/onboard/verifySms";
    return this.http.post(url, body);
  }

  // Onboarding Verify WhatsApp Code
  verifyWhatsAppCode(providerId: string, code: string) {
    const body = { providerId, code };
    var url = baseUrl + "/v1/marketplace/onboard/verifyWhatsapp";
    return this.http.post(url, body);
  }

  //Onboarding Register Provider
  registerProvider(provider: Provider, profile: Profile) {
    var url = baseUrl + "/v1/marketplace/onboard/provider";
    return this.http.post(url, { provider: provider, profile: profile });
  }

  //Onboarding Update Provider
  updateProvider(provider: Provider) {
    var url = baseUrl + "/v1/marketplace/onboard/provider";
    return this.http.put(url, provider);
  }

  // Onboarding Verify PromoCode
  verifyPromoCode(promo_code_name: string, provider_id: string) {
    const body = { promo_code_name, provider_id };
    var url = baseUrl + "/v1/marketplace/onboard/pricing/verifyPromoCode";
    return this.http.post(url, body);
  }
  //Onboarding Unsubscribe Provider
  unsubscribeProvider(provider_subdomain: string, token_no: string, captchaResponse: string) {
    const body = { provider_subdomain, token_no, captchaResponse };
    var url = baseUrl + "/v1/marketplace/onboard/unsubscribe?"
    return this.http.post(url, body);
  }

  // Log user in twitter
  twitterLogin() {
    var url = baseUrl + "/signin/twitter";
    console.log("in twitter login in marketplace");
    return window.location.replace(url);
  }

  // Log user in facebook
  facebookLogin() {
    var url = baseUrl + "/signin/facebook";
    return window.location.replace(url);
  }

  // Log user in linkedin
  linkedinLogin() {
    var url = baseUrl + "/signin/linked_in";
    return window.location.replace(url);
  }

  ///create session API
  InitiateSubscription(providerId: string, productID: string, localPrice: BillingAmount, internationalPrice: number, referralCode: string, calendlyLink: string) {
    var url = baseUrl + "/v1/marketplace/billing/stripe/subscribe";
    const body = {
      provider_id: providerId,
      product_id: productID,
      localPrice: localPrice,
      international_price: internationalPrice,
      referral_code: referralCode,
      calendly_link: calendlyLink
    };
    return this.http.post(url, body);
  }

  ///fetch stripe product prices
  GetStripeProductPrices(providerID) {
    var url = baseUrl + "/v1/marketplace/billing/stripe/product_prices";
    var queryParams = "?providerTmpId=" + providerID
    return this.http.get(url + queryParams);
  }

  ///get latest local currency compared by 1 USD 
  getLocalPriceFromUSD(currency) {
    var url = baseUrl + "/v1/marketplace/onboard/conversion_rate";
    var queryParams = "?currency=" + currency;

    return this.http.get(url + queryParams);
  }

  sendActivationLink(user_email: string, user_password: string, captchaResponse: string) {
    const body = { user_email, user_password, captchaResponse };
    var url = baseUrl + "/v1/marketplace/onboard/emailVerification/activationLink";
    return this.http.post(url, body);
  }
}
