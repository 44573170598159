
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationForm } from './../../app/models/RegistrationView';
import { MarketplaceService } from '../service/marketplace.service';
import { UplaodImagesService } from '../service/uplaod-images.service';
import { environment } from "./../../environments/environment";
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.css']
})
export class SocialLoginComponent implements OnInit {
  service: string;
  loginErr: string = "";

  wizard: number = 1;
  userModel = new RegistrationForm();
  public recaptchaPublicKey = environment.recaptcha.siteKey;
  queryCaptchaResponse = '';
  isEmailMatchs: boolean = true;

  //@ViewChild("f") f: NgForm;
  @ViewChild('password') passwordField: ElementRef;
  @ViewChild('confirmation_password') ConfPaawordField: ElementRef;

  constructor(private uploadService: UplaodImagesService,
    private onboarding: MarketplaceService,
    private router: Router,
    private route: ActivatedRoute) {

    this.setReferralCode()
  }

  ngOnInit(): void {
    this.getUrlParams()
  }

  setReferralCode() {
    localStorage.removeItem("guid_refferralCode");
    this.route.queryParams.subscribe(data => {
      if (data.referralCode) {
        console.log(data.referralCode);
        localStorage.setItem("guid_refferralCode", data.referralCode);
      }
    })
  }
  URLPreProcess() {
    var hashURL = window.location.hash;
    var urlParamFirstIdx = hashURL.indexOf("?")
    if (urlParamFirstIdx == -1) {
      urlParamFirstIdx = hashURL.length
    }
    var urlParamsstring = hashURL.substring(urlParamFirstIdx)
    return urlParamsstring
  }

  getUrlParams() {
    //const urlParams = new URLSearchParams(window.location.search); =>> doesn't work with (# url) 
    ///so i need to make preprocessing to can fetch parameters 
    ///= >>>>
    var WindowLocationSearch = this.URLPreProcess() // this function will return as window.location.search 
    const urlParams = new URLSearchParams(WindowLocationSearch);

    if (urlParams.toString().includes('errMsg')) {
      this.loginErr = urlParams.get("errMsg");
      console.log(this.loginErr)
      document.getElementById("warningSign").style.display = 'inline';
      document.getElementById("errorMsg").style.display = 'inline';
      document.getElementById("errorMsg").innerHTML = this.loginErr;
    }
    else {
      document.getElementById("warningSign").style.display = 'none';
      document.getElementById("errorMsg").style.display = "none";
    }
  }
  twitterLogin() {
    this.service = "twitter";
    return this.onboarding.twitterLogin();
  }

  facebookLogin() {
    this.service = "facebook";
    return this.onboarding.facebookLogin();
  }

  linkedinLogin() {
    this.service = "linkedin";
    return this.onboarding.linkedinLogin();
  }

  RegistrationFormTransfer(trasferedFlag: boolean) {
    if (trasferedFlag) {
      this.wizard = 2;
    } else {
      this.wizard = 1;
    }
  }
  public resolved(captchaResponse: string) {
    this.queryCaptchaResponse = captchaResponse;
  }

  CheckValidtion() {
    if (this.userModel.confirmation_password && this.userModel.password) {
      this.isEmailMatchs = this.userModel.confirmation_password === this.userModel.password;
    }
    //console.log(this.userModel.confirmation_password, this.userModel.password, this.isEmailMatchs);
  }
  onSubmit(form) {
    //console.log(form.value, this.userModel.email, this.userModel.password);

    //console.log(`Resolved captcha with response: ${this.queryCaptchaResponse}`);
    this.onboarding.sendActivationLink(this.userModel.email, this.userModel.password, this.userModel.recaptchaResponse).subscribe((data: any) => {
      if (Object.keys(data.length == 0).length == 0) {
        this.router.navigate(["/finish"]);
      }
    });
  }
}
