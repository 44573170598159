export class Provider {
  userId: string;
  serviceKind: Kind;
  serviceIdentifier: string;
  subDomain: string;
  introVideoUrl: string;
  introVideoRaw: string;
  whatsapp: Whatsapp;
  deprecatedConsultationPrice: number;
  consultationPrice: BillingAmount;
  description: LocalizedText[];
  descriptionImageUrl: string;
  callPricePerMin: BillingAmount;
  callPhoneNumber: string;
  publicPhoneNumber: string;
  callPricesPerMin: BillingAmount[];
  reminderNotificationsCron: string;
  calendlyLink: string;
  isNeedNumber: boolean;
  socialMedia: SocialMedia;
  socialMediaId: string;
}

export enum SocialMedia {
  TWITTER = 0,
  FACEBOOK = 1,
  LINKEDIN = 2,
}

export enum Kind {
  UNDEFINED = 0,
  WHATSAPP = 1,
  MESSENGER = 2,
}

export class Whatsapp {
  groupSubjectTemplates: LocalizedText[];
  groupIconUrl: string;
  groupDescriptionTemplates: LocalizedText[];
}

export class LocalizedText {
  languageTag: string;
  body: string;
}

export class BillingAmount {
  valueDeprecated: number;
  currencyCode: string;
  value: number;
}
