import { environment } from "./../../environments/environment.prod";
import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UplaodImagesService {
  constructor(private http: HttpClient) { }

  uploadImage(file: File) {
    const formdata: FormData = new FormData();

    formdata.append("file", file);

    return this.http.post(environment.apiUrl + "/api/file/upload", formdata);
  }
}
